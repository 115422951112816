var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('vue-element-loading',{attrs:{"active":_vm.appLoading,"is-full-screen":true,"background-color":"#FFFFFF","color":"#182444","spinner":"bar-fade-scale"}}),(_vm.ServerError)?_c('ServerError'):_vm._e(),_c('v-snackbar',{attrs:{"color":"#ff6200","right":"","top":"","timeout":"2000"},model:{value:(_vm.showSnackBar),callback:function ($$v) {_vm.showSnackBar=$$v},expression:"showSnackBar"}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{staticClass:"align-self-center",attrs:{"text-left":""}},[_c('span',{staticStyle:{"color":"white"}},[_vm._v(" "+_vm._s(_vm.msg)+" ")])]),_c('v-flex',{attrs:{"text-right":""}},[_c('v-btn',{attrs:{"small":"","ripple":false,"text":""},on:{"click":function($event){_vm.showSnackBar = false}}},[_c('v-icon',{staticStyle:{"color":"white"}},[_vm._v("mdi-close")])],1)],1)],1)],1),_c('v-layout',{attrs:{"wrap":"","justify-start":"","pt-2":""}},[_c('v-flex',{attrs:{"xs12":"","sm3":"","align-self-center":"","pl-8":""}},[_c('span',{staticClass:"title1",style:({
          'font-size':
            _vm.$vuetify.breakpoint.name == 'xs'
              ? '20px'
              : _vm.$vuetify.breakpoint.name == 'sm'
              ? '20px'
              : _vm.$vuetify.breakpoint.name == 'md'
              ? '25px'
              : _vm.$vuetify.breakpoint.name == 'lg'
              ? '25px'
              : '30px',
        })},[_vm._v("Add Policies")])])],1),_c('v-layout',{attrs:{"wrap":"","justify-start":"","pa-4":"","fill-height":""}},[_c('v-flex',{attrs:{"xs12":"","sm6":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm10":"","text-right":""}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","type":"text","hide-details":"auto"},model:{value:(_vm.newPolicy),callback:function ($$v) {_vm.newPolicy=$$v},expression:"newPolicy"}}),_c('span',{staticClass:"title2",staticStyle:{"color":"orange"},on:{"click":function($event){return _vm.addPolicies()}}},[_vm._v("Create "),_c('v-icon',{attrs:{"color":"orange"}},[_vm._v("mdi-plus")])],1)],1),_c('v-flex',{attrs:{"xs12":"","sm10":"","pt-4":"","text-left":""}},[(_vm.Policies.length > 0)?_c('v-layout',{attrs:{"wrap":""}},_vm._l((_vm.Policies),function(contact,index){return _c('v-flex',{key:index,attrs:{"xs12":"","pt-4":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs11":""}},[_c('v-text-field',{attrs:{"dense":"","hide-details":"","outlined":"","type":"text","hide-spin-buttons":"","disabled":""},model:{value:(_vm.Policies[index]),callback:function ($$v) {_vm.$set(_vm.Policies, index, $$v)},expression:"Policies[index]"}})],1),_c('v-flex',{attrs:{"xs1":"","align-self-center":"","text-center":""}},[_c('v-icon',{on:{"click":function($event){return _vm.deletePolicies(index)}}},[_vm._v(" mdi-delete ")])],1)],1)],1)}),1):_vm._e()],1)],1),_c('v-layout',{attrs:{"wrap":"","justify-center":"","pb-8":""}},[_c('v-flex',{attrs:{"xs12":"","sm10":"","pt-4":"","text-left":""}},[_c('v-layout',{attrs:{"wrap":"","justify-end":""}},[_c('v-flex',{attrs:{"xs12":"","sm5":""}},[_c('v-btn',{staticClass:"btnstly",staticStyle:{"cursor":"pointer"},attrs:{"block":""},on:{"click":function($event){return _vm.validation()}}},[_c('span',{staticStyle:{"font-family":"LexendFont","text-transform":"none"}},[_vm._v("Save")])])],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }