<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#182444"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#ff6200" right top timeout="2000">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-start pt-2>
      <v-flex xs12 sm3 align-self-center pl-8>
        <span
          class="title1"
          :style="{
            'font-size':
              $vuetify.breakpoint.name == 'xs'
                ? '20px'
                : $vuetify.breakpoint.name == 'sm'
                ? '20px'
                : $vuetify.breakpoint.name == 'md'
                ? '25px'
                : $vuetify.breakpoint.name == 'lg'
                ? '25px'
                : '30px',
          }"
          >Add Location</span
        >
      </v-flex>
    </v-layout>
    <v-layout wrap justify-start pa-4>
      <v-flex xs12 sm6>
        <v-layout wrap justify-center>
          <v-flex xs12 sm10 text-left>
            <span class="title2">Location</span>
            <v-text-field
              dense
              outlined
              type="text"
              v-model="location"
              :rules="[rules.required]"
              hide-details="auto"
            >
            </v-text-field>
          </v-flex>

          <v-flex xs12 sm10 pt-4 text-left>
            <span class="title2">Distance from main town</span>
            <v-text-field
              dense
              outlined
              type="number"
              v-model="distanceFromTown"
              :rules="[rules.required]"
              hide-details="auto"
            >
            </v-text-field>
          </v-flex>

          <v-flex xs12 sm10 pt-4 text-left>
            <span class="title2">Main town name</span>
            <v-text-field
              dense
              outlined
              type="text"
              v-model="mainTownName"
              :rules="[rules.required]"
              hide-details="auto"
            >
            </v-text-field>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 sm6>
        <v-layout wrap justify-center>
          <v-flex xs12 sm10 text-left>
            <span class="title2">Location link</span>
            <v-text-field
              dense
              outlined
              type="text"
              v-model="link"
              :rules="[rules.required]"
              hide-details="auto"
            >
            </v-text-field>
          </v-flex>
          <v-flex xs12 sm10 text-left>
            <span class="title2">Address</span>
            <v-textarea
              dense
              outlined
              type="text"
              v-model="address"
              :rules="[rules.required]"
              hide-details="auto"
            >
            </v-textarea>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center>
          <v-flex xs12 sm10 pt-4 text-right>
            <v-btn @click="validation()" class="btnstly" style="cursor: pointer"
              ><span style="font-family: LexendFont; text-transform: none"
                >Save</span
              ></v-btn
            >
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
  <script>
import axios from "axios";
export default {
  props: ['hotelId'],
  data() {
    return {
      showSnackBar: false,
      timeout: 2000,
      ServerError: false,
      appLoading: false,
      msg: null,
      newCategory: "",
      Categorys: [],
      address: "",
      link: "",
      mainTownName: "",
      distanceFromTown: "",
      location: "",
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",

        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return !value || pattern.test(value) || "Invalid e-mail.";
        },
      },
    };
  },

  methods: {
    validation() {
      if (!this.location) {
        this.msg = "Please enter location name";
        this.showSnackBar = true;
        return;
      } else if (!this.mainTownName) {
        this.msg = "Please enter main town name";
        this.showSnackBar = true;
        return;
      } else if (!this.distanceFromTown) {
        this.msg = "Please enter distance from main town";
        this.showSnackBar = true;
        return;
      } else if (!this.link) {
        this.msg = "Please enter location link";
        this.showSnackBar = true;
        return;
      }else if (!this.address) {
        this.msg = "Please enter address";
        this.showSnackBar = true;
        return;
      } 
      else {
        this.add();
      }
    },
    add() {
      console.log("id in 2:",this.hotelId)
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/hotel/add/location",
        data: {
          locationName: this.location,
          distanceFromMainTown: this.distanceFromTown,
          townName: this.mainTownName,
          googleLocation: this.link,
          address: this.address,
          hotelId: this.hotelId
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      }).then((response) => {
        this.appLoading = false;
        if (response.data.status == true) {
          this.msg = response.data.msg;
          this.showSnackBar = true;
          this.$emit('next-tab');
        } else {
          this.msg = response.data.msg;
          this.showSnackBar = true;
        }
      });
      // }
    },
  },
};
</script>
<style scoped>
.btnstly {
  background: linear-gradient(
    to bottom,
    rgba(255, 133, 86, 1),
    rgba(247, 75, 9, 1),
    rgba(255, 70, 0, 1)
  );
  color: white;
}
</style>