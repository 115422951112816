<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#182444"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#ff6200" right top timeout="2000">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-start pt-2>
      <v-flex xs12 sm3 align-self-center pl-8>
        <span
          class="title1"
          :style="{
            'font-size':
              $vuetify.breakpoint.name == 'xs'
                ? '20px'
                : $vuetify.breakpoint.name == 'sm'
                ? '20px'
                : $vuetify.breakpoint.name == 'md'
                ? '25px'
                : $vuetify.breakpoint.name == 'lg'
                ? '25px'
                : '30px',
          }"
          >Add Amenities</span
        >
      </v-flex>
    </v-layout>

    <v-layout wrap justify-start pt-4>
      <v-flex xs12 sm6>
        <v-layout wrap justify-center>
          <v-flex xs12 sm10 text-left>
            <span class="title2">Hotel Chain Name</span>
            <v-text-field
              dense
              outlined
              type="text"
              v-model="hotelchainName"
              hide-details="auto"
            >
            </v-text-field>
          </v-flex>
          <v-flex xs12 sm10 text-left>
            <span class="title2">Built Year</span>
            <v-text-field
              dense
              outlined
              type="text"
              v-model="builtYear"
              hide-details="auto"
            >
            </v-text-field>
          </v-flex>
          <v-flex xs12 sm10 text-left>
            <span class="title2">VCC Currency</span>
            <!-- <v-text-field
              dense
              outlined
              type="text"
              v-model="VCCcurrency"
             
              hide-details="auto"
            >
            </v-text-field> -->
            <v-select
              dense
              outlined
              v-model="VCCcurrency"
              :items="currencies"
              item-text="name"
              item-value="code"
              hide-details="auto"
            ></v-select>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 sm6>
        <v-layout wrap justify-center>
          <v-flex xs12 sm10 text-left>
            <span class="title2">Currency</span>
            <!-- <v-text-field
              dense
              outlined
              type="text"
              v-model="currency"
             
              hide-details="auto"
            >
            </v-text-field> -->
            <v-select
              dense
              outlined
              v-model="currency"
              :items="currencies"
              item-text="name"
              item-value="code"
              hide-details="auto"
            ></v-select>
          </v-flex>
          <v-flex xs12 sm10 text-left>
            <span class="title2">Time Zone</span>
            <!-- <v-autocomplete
              dense
              outlined
              v-model="timeZone"
              :items="timeZones"
              hide-details="auto"
              placeholder="Select Time Zone"
            ></v-autocomplete> -->
            <v-select
              dense
              outlined
              v-model="timeZone"
              :items="timeZones"
              item-text="name"
              item-value="value"
              hide-details="auto"
            ></v-select>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-start pt-4>
      <v-flex xs12 sm3 align-self-center pl-8>
        <span
          class="title1"
          :style="{
            'font-size':
              $vuetify.breakpoint.name == 'xs'
                ? '20px'
                : $vuetify.breakpoint.name == 'sm'
                ? '20px'
                : $vuetify.breakpoint.name == 'md'
                ? '25px'
                : $vuetify.breakpoint.name == 'lg'
                ? '25px'
                : '30px',
          }"
          >Basic facilities</span
        >
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center px-4 pt-4>
      <v-flex xs12 sm4>
        <v-layout wrap justify-center>
          <v-flex xs12 text-center>
            <v-switch
              v-model="facilities.indoorGames"
              label="Indoor Games"
              inset
              color="#002635"
            ></v-switch>
          </v-flex>
          <v-flex xs12 text-center>
            <v-switch
              v-model="facilities.roomService"
              label="Room Service"
              inset
              color="#002635"
            ></v-switch>
          </v-flex>
          <v-flex xs12 text-center>
            <v-switch
              v-model="facilities.elivatorOrLift"
              label="Elevator/Lift"
              inset
              color="#002635"
            ></v-switch>
          </v-flex>
          <v-flex xs12 text-center>
            <v-switch
              v-model="facilities.paidAirportTransfer"
              label="Paid Airport Transfers"
              inset
              color="#002635"
            ></v-switch>
          </v-flex>
          <v-flex xs12 text-center>
            <v-switch
              v-model="facilities.kidsPlayArea"
              label="Kids Play Area"
              inset
              color="#002635"
            ></v-switch>
          </v-flex>
          <v-flex xs12 text-center>
            <v-switch
              v-model="facilities.dinningArea"
              label="Dining Area"
              inset
              color="#002635"
            ></v-switch>
          </v-flex>
          <v-flex xs12 text-center>
            <v-switch
              v-model="facilities.cctv"
              label="CCTV"
              inset
              color="#002635"
            ></v-switch>
          </v-flex>
          <v-flex xs12 text-center>
            <v-switch
              v-model="facilities.activityCenter"
              label="Activity Center"
              inset
              color="#002635"
            ></v-switch>
          </v-flex>
          <v-flex xs12 text-center>
            <v-switch
              v-model="facilities.lugggeStorage"
              label="Luggage Storage"
              inset
              color="#002635"
            ></v-switch>
          </v-flex>
        </v-layout>
      </v-flex>

      <v-flex xs12 sm4>
        <v-layout wrap justify-center>
          <v-flex xs12 text-center>
            <v-switch
              v-model="facilities.concierge"
              label="Concierge"
              inset
              color="#002635"
            ></v-switch>
          </v-flex>
          <v-flex xs12 text-center>
            <v-switch
              v-model="facilities.multilingualStaff"
              label="Multilingual Staff"
              inset
              color="#002635"
            ></v-switch>
          </v-flex>
          <v-flex xs12 text-center>
            <v-switch
              v-model="facilities.luggageAssistance"
              label="Luggage Assistance"
              inset
              color="#002635"
            ></v-switch>
          </v-flex>
          <v-flex xs12 text-center>
            <v-switch
              v-model="facilities.doctorOnCall"
              label="Doctor on Call"
              inset
              color="#002635"
            ></v-switch>
          </v-flex>
          <v-flex xs12 text-center>
            <v-switch
              v-model="facilities.lounge"
              label="Lounge"
              inset
              color="#002635"
            ></v-switch>
          </v-flex>
          <v-flex xs12 text-center>
            <v-switch
              v-model="facilities.reception"
              label="Reception"
              inset
              color="#002635"
            ></v-switch>
          </v-flex>
          <v-flex xs12 text-center>
            <v-switch
              v-model="facilities.balconyOrTerrace"
              label="Balcony/Terrace"
              inset
              color="#002635"
            ></v-switch>
          </v-flex>
          <v-flex xs12 text-center>
            <v-switch
              v-model="facilities.souvenirShop"
              label="Souvenir Shop"
              inset
              color="#002635"
            ></v-switch>
          </v-flex>
          <v-flex xs12 text-center>
            <v-switch
              v-model="facilities.printer"
              label="Printer"
              inset
              color="#002635"
            ></v-switch>
          </v-flex>
        </v-layout>
      </v-flex>

      <v-flex xs12 sm3>
        <v-layout wrap justify-center>
          <v-flex xs12 text-center>
            <v-switch
              v-model="facilities.businessCenter"
              label="Business Center"
              inset
              color="#002635"
            ></v-switch>
          </v-flex>
          <v-flex xs12 text-center>
            <v-switch
              v-model="facilities.gameRoom"
              label="Game Room"
              inset
              color="#002635"
            ></v-switch>
          </v-flex>
          <v-flex xs12 text-center>
            <v-switch
              v-model="facilities.sitoutArea"
              label="Sitout Area"
              inset
              color="#002635"
            ></v-switch>
          </v-flex>
          <v-flex xs12 text-center>
            <v-switch
              v-model="facilities.bonfirePit"
              label="Bonfire Pit"
              inset
              color="#002635"
            ></v-switch>
          </v-flex>
          <v-flex xs12 text-center>
            <v-switch
              v-model="facilities.picnicArea"
              label="Picnic Area"
              inset
              color="#002635"
            ></v-switch>
          </v-flex>
          <v-flex xs12 text-center>
            <v-switch
              v-model="facilities.kidsMenu"
              label="Kids Menu"
              inset
              color="#002635"
            ></v-switch>
          </v-flex>
          <v-flex xs12 text-center>
            <v-switch
              v-model="facilities.securityGuards"
              label="Security Guard"
              inset
              color="#002635"
            ></v-switch>
          </v-flex>
          <v-flex xs12 text-center>
            <v-switch
              v-model="facilities.barLounges"
              label="Bar Lounges"
              inset
              color="#002635"
            ></v-switch>
          </v-flex>
          <v-flex xs12 text-center>
            <v-switch
              v-model="facilities.spa"
              label="Spa"
              inset
              color="#002635"
            ></v-switch>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <v-layout wrap justify-center px-4 pb-4>
      <v-flex xs12 sm4 text-center>
        <v-layout wrap justify-center>
          <v-flex xs12 text-center>
            <v-switch
              v-model="facilities.nonSmokingRooms"
              label="Non-smoking rooms"
              inset
              color="#002635"
            ></v-switch>
          </v-flex>
          <v-flex xs12 text-center>
            <v-switch
              v-model="facilities.privateParking"
              label="Private parking"
              inset
              color="#002635"
            ></v-switch>
          </v-flex>
          <v-flex xs12 text-center>
            <v-switch
              v-model="facilities.fireExtinguisher"
              label="Fire extinguisher"
              inset
              color="#002635"
            ></v-switch>
          </v-flex>
          <v-flex xs12 text-center>
            <v-switch
              v-model="facilities.powerBackup"
              label="Power backup"
              inset
              color="#002635"
            ></v-switch>
          </v-flex>
          <v-flex xs12 text-center>
            <v-switch
              v-model="facilities.bathRooms"
              label="Bathroom"
              inset
              color="#002635"
            ></v-switch>
          </v-flex>
          <v-flex xs12 text-center>
            <v-switch
              v-model="facilities.bedroom"
              label="Bedroom"
              inset
              color="#002635"
            ></v-switch>
          </v-flex>
          <v-flex xs12 text-center>
            <v-switch
              v-model="facilities.view"
              label="View"
              inset
              color="#002635"
            ></v-switch>
          </v-flex>
          <v-flex xs12 text-center>
            <v-switch
              v-model="facilities.restaurant"
              label="Restaurant"
              inset
              color="#002635"
            ></v-switch>
          </v-flex>
         
        </v-layout>
      </v-flex>

      <v-flex xs12 sm4 text-center>
        <v-layout wrap justify-center>
          <v-flex xs12 text-center>
            <v-switch
              v-model="facilities.conference"
              label="Conference"
              inset
              color="#002635"
            ></v-switch>
          </v-flex>
          <v-flex xs12 text-center>
            <v-switch
              v-model="facilities.barbeque"
              label="Barbeque"
              inset
              color="#002635"
            ></v-switch>
          </v-flex>
          <v-flex xs12 text-center>
            <v-switch
              v-model="facilities.sportsCourt"
              label="Sports court"
              inset
              color="#002635"
            ></v-switch>
          </v-flex>
          <v-flex xs12 text-center>
            <v-switch
              v-model="facilities.banquetHall"
              label="Banquet Hall"
              inset
              color="#002635"
            ></v-switch>
          </v-flex>
         
          <v-flex xs12 text-center>
            <v-switch
              v-model="facilities.mediaAndTechnology"
              label="Media & Technology"
              inset
              color="#002635"
            ></v-switch>
          </v-flex>
          <v-flex xs12 text-center>
            <v-switch
              v-model="facilities.internet"
              label="Internet"
              inset
              color="#002635"
            ></v-switch>
          </v-flex>
          <v-flex xs12 text-center>
            <v-switch
              v-model="facilities.swimmingPool"
              label="Swimming pool"
              inset
              color="#002635"
            ></v-switch>
          </v-flex>
          <v-flex xs12 text-center>
            <v-switch
              v-model="facilities.cafes"
              label="Cafes"
              inset
              color="#002635"
            ></v-switch>
          </v-flex>
        </v-layout>
      </v-flex>

      <v-flex xs12 sm3 text-center>
        <v-layout wrap justify-center>
          <v-flex xs12 text-center>
            <v-switch
              v-model="facilities.meetingrooms"
              label="Meeting room"
              inset
              color="#002635"
            ></v-switch>
          </v-flex>
          <v-flex xs12 text-center>
            <v-switch
              v-model="facilities.outdoorArea"
              label="Outdoor area"
              inset
              color="#002635"
            ></v-switch>
          </v-flex>
          
          <v-flex xs12 text-center>
            <v-switch
              v-model="facilities.parking"
              label="Parking"
              inset
              color="#002635"
            ></v-switch>
          </v-flex>
          <v-flex xs12 text-center>
            <v-switch
              v-model="facilities.general"
              label="General"
              inset
              color="#002635"
            ></v-switch>
          </v-flex>
          <v-flex xs12 text-center>
            <v-switch
              v-model="facilities.langagesSpoken"
              label="Languages spoken"
              inset
              color="#002635"
            ></v-switch>
          </v-flex>
          <v-flex xs12 text-center>
            <v-switch
              v-model="facilities.campFire"
              label="Campfire"
              inset
              color="#002635"
            ></v-switch>
          </v-flex>
          <v-flex xs12 text-center>
            <v-switch
              v-model="facilities.fitness"
              label="Fitness"
              inset
              color="#002635"
            ></v-switch>
          </v-flex>
         
        </v-layout>
        <v-layout wrap justify-center>
          <v-flex xs12 sm6 pt-4 text-right>
            <v-btn block @click="validation()" class="btnstly" style="cursor: pointer"
              ><span style="font-family: LexendFont; text-transform: none"
                >Save</span
              ></v-btn
            >
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
  <script>
import axios from "axios";
import currencyCodes from "currency-codes";
import moment from "moment-timezone";
export default {
  props: ['hotelId'],
  data() {
    return {
      showSnackBar: false,
      timeout: 2000,
      ServerError: false,
      appLoading: false,
      msg: null,
      hotelchainName: "",
      builtYear: "",
      VCCcurrency: "",
      currency: "",
      timeZone: "",
      timeZones: [],
      currencies: [],
      facilities: {
        barbeque:false,
        campFire:false,
        nonSmokingRooms: false,
        privateParking: false,
        fireExtinguisher: false,
        powerBackup: false,
        bathRooms: false,
        bedroom: false,
        view: false,
        restaurant: false,
        conference: false,
        sportsCourt: false,
        banquetHall: false,
        outdoors: false,
        mediaAndTechnology: false,
        internet: false,
        swimmingPool: false,
        cafes: false,
        meetingrooms: false,
        outdoorArea: false,
        parking: false,
        general: false,
        langagesSpoken: false,
        barLounges: false,
        fitness: false,
        spa: false,
        indoorGames: false,
        roomService: false,
        elivatorOrLift: false,
        paidAirportTransfer: false,
        kidsPlayArea: false,
        dinningArea: false,
        cctv: false,
        activityCenter: false,
        lugggeStorage: false,
        concierge: false,
        multilingualStaff: false,
        luggageAssistance: false,
        doctorOnCall: false,
        lounge: false,
        reception: false,
        balconyOrTerrace: false,
        souvenirShop: false,
        printer: false,
        businessCenter: false,
        gameRoom: false,
        sitoutArea: false,
        bonfirePit: false,
        picnicArea: false,
        kidsMenu: false,
        securityGuards: false,
        barOrLounges:false,
      },
    };
  },
  created() {
    this.timeZones = this.getTimeZones();
    this.currencies = this.getCurrencies();
  },
  methods: {
    getTimeZones() {
      const zones = moment.tz.names();
      return zones.map((zone) => ({
        name: zone,
        value: zone,
      }));
    },
    getCurrencies() {
      return currencyCodes.codes().map((code) => {
        const currency = currencyCodes.code(code);
        return {
          code: currency.code,
          name: currency.currency,
          symbol: currency.symbol,
        };
      });
    },
    addCategoryField() {
      // Add the new Category to the array if it's not empty
      if (this.newCategory) {
        this.Categorys.push(this.newCategory);
        this.newCategory = ""; // Reset the new Category field
      }
    },
    deleteCategory(index) {
      this.Categorys.splice(index, 1); // Remove the Category at the given index
    },
    validation() {
      if (!this.hotelchainName) {
        this.msg = "Please enter hotel chain name";
        this.showSnackBar = true;
        return;
      } else if (!this.builtYear) {
        this.msg = "Please enter build year";
        this.showSnackBar = true;
        return;
      } else if (!this.VCCcurrency) {
        this.msg = "Please choose VCC currency";
        this.showSnackBar = true;
        return;
      } else if (!this.currency) {
        this.msg = "Please choose currency";
        this.showSnackBar = true;
        return;
      }else if (!this.timeZone) {
        this.msg = "Please choose time zone";
        this.showSnackBar = true;
        return;
      } 
      else {
        this.add();
      }
    },
    add() {
      this.appLoading = true;

      axios({
        method: "POST",
        url: "/hotel/add/facilities",
        data: {
          hotelChainName: this.hotelchainName,
          builtYear: this.builtYear,
          vccCurrency: this.VCCcurrency,
          currency: this.currency,
          timeZone: this.timeZone,
          facilities: this.facilities,
          hotelId: this.hotelId
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      }).then((response) => {
        this.appLoading = false;
        if (response.data.status == true) {
          this.msg = response.data.msg;
          this.showSnackBar = true;
          this.$emit('next-tab');
        } else {
          this.msg = response.data.msg;
          this.showSnackBar = true;
        }
      });
      // }
    },
  },
};
</script>
<style scoped>
.btnstly {
  background: linear-gradient(
    to bottom,
    rgba(255, 133, 86, 1),
    rgba(247, 75, 9, 1),
    rgba(255, 70, 0, 1)
  );
  color: white;
}
</style>
